<template>
  <j-abm
    name="template"
    avatar="link"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: false,
    }">
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-xl-6">
              <h5>{{ item.name }}</h5>
              <div v-if="item.expiration">
                {{ $t(`msg.template.expired.${item.expired}`) }} {{ item.expiration | elapsed }}
              </div>
              <div v-else>
                {{ $t('msg.template.everlasting') }}
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <!-- Url -->
              <a-input
                v-model="item.url"
                :read-only="true"
                size="large">
                <a-icon
                  slot="prefix"
                  type="lock"
                  class="text-success" />
              </a-input>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions="{item}">
      <!-- Copy -->
      <a-button
        shape="circle"
        icon="copy"
        type="link"
        @click="() => onCopy(item)" />
      <!-- Edit -->
      <router-link :to="`templates/${item.id}/update`">
        <a-button
          shape="circle"
          icon="setting" />
      </router-link>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import JAbm from '@/views/shared/abm/search'
export default {
  components: {
    JAbm,
  },
  computed: {
    account() {
      return this.$route.params.account
    },
    suppliers() {
      return {
        fetchAll: page => lms.account.fetchTemplates(this.account, page),
      }
    },
  },
  methods: {
    onCopy(item) {
      navigator.clipboard.writeText(item.url)
      this.$message.success(this.$t('msg.copied'))
    },
  },
}
</script>
