var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"template","avatar":"link","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: false,
  }},scopedSlots:_vm._u([{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('h5',[_vm._v(_vm._s(item.name))]),(item.expiration)?_c('div',[_vm._v(" "+_vm._s(_vm.$t(("msg.template.expired." + (item.expired))))+" "+_vm._s(_vm._f("elapsed")(item.expiration))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('msg.template.everlasting'))+" ")])]),_c('div',{staticClass:"col-12 col-xl-6"},[_c('a-input',{attrs:{"read-only":true,"size":"large"},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}},[_c('a-icon',{staticClass:"text-success",attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)])])])]}},{key:"actions",fn:function(ref){
  var item = ref.item;
return [_c('a-button',{attrs:{"shape":"circle","icon":"copy","type":"link"},on:{"click":function () { return _vm.onCopy(item); }}}),_c('router-link',{attrs:{"to":("templates/" + (item.id) + "/update")}},[_c('a-button',{attrs:{"shape":"circle","icon":"setting"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }